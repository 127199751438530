import React, { Component } from 'react';

class Projects extends Component {
   render() {

      if (this.props.data) {
         // var skillmessage = this.props.data.skillmessage;
         // var education = this.props.data.education.map(function(education){
         //   return <div key={education.school}><h3>{education.school}</h3>
         //   <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
         //   <p>{education.description}</p></div>
         // })
         var work = this.props.data.projects.map(function (project) {
            return <div key={project.key}><h3>{project.summary}</h3>
               {/* <p className="info">{project.summary}</p> */}
               <ul>
                  {project.details.map((desc, index) => (
                     <li key={index}>{desc}</li>
                  ))}
               </ul>
            </div>
         })
         // var skills = this.props.data.skills.map(function(skills){
         //   var className = 'bar-expand '+skills.name.toLowerCase();
         //   return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
         // })
      }

      return (
         <section id="resume">
            {/* <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div> */}


            <div className="row work">

               <div className="three columns header-col">
                  <h1><span>Projects</span></h1>
               </div>

               <div className="nine columns main-col">
                  {work}
               </div>
            </div>


            {/* 
      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">

            <p>{skillmessage}
            </p>

				<div className="bars">
				   <ul className="skills">
					  {skills}
					</ul>
				</div>
			</div>
      </div> */}
         </section>
      );
   }
}

export default Projects;
